#b {
    padding-top: 35px;
}

#h {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin: 0;
    padding-left: 30px;
    padding-top: 5px;
    background: #232f3e;
    min-height: 35px;
    color: #fff;
  }
