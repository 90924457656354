#device-listing {
  overflow-y: scroll;
}

.connected {
  color: green;
}

.disconnected {
  color: red;
}

.no-events {
  color: red;
  font-weight: bold;
  margin-left: 1em;
}
