#map {
  width: 100%;
  height: 75vh;
}

.device {
  border-radius: 50%;
  height: 1em;
  width: 1em;
  cursor: pointer;
}

html {
  font-size: 1rem;
}

.map-popup h3 {
  margin: 0 0 0.25rem 0;
}

.map-popup h4 {
  margin: 0.75rem 0 0.25rem 0;
}

.map-popup table tr td:first-child {
  font-weight: bold;
  padding-right: 1rem;
}

#device-listing {
  max-height: calc(75vh / 2 + 10px);
}

#map-header .focused-device {
  font-size: 0.875em;
  color: #666;
}

#map-header .loading-spinner {
  margin-left: 0.5em;
}
